// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\r\n    font-family: 'Noto Sans KR', sans-serif;\r\n}\r\n\r\n.App {\r\n}\r\n\r\nbutton {\r\n    cursor: pointer;\r\n    border: none\r\n}\r\n\r\ninput,select {\r\n    /*cursor: pointer;*/\r\n}\r\n\r\ninput,textarea,select:focus {\r\n    outline: none;\r\n    /*cursor: pointer;*/\r\n}\r\n\r\nmain {\r\n    width: 85%;\r\n    float: right;\r\n    margin-top: 60px;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;IACI,uCAAuC;AAC3C;;AAEA;AACA;;AAEA;IACI,eAAe;IACf;AACJ;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;700&display=swap');\r\n* {\r\n    font-family: 'Noto Sans KR', sans-serif;\r\n}\r\n\r\n.App {\r\n}\r\n\r\nbutton {\r\n    cursor: pointer;\r\n    border: none\r\n}\r\n\r\ninput,select {\r\n    /*cursor: pointer;*/\r\n}\r\n\r\ninput,textarea,select:focus {\r\n    outline: none;\r\n    /*cursor: pointer;*/\r\n}\r\n\r\nmain {\r\n    width: 85%;\r\n    float: right;\r\n    margin-top: 60px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
