import axios from "../Axios";
import moment from "moment";

export const storeData = async (key, value) => {
    try {
        const jsonValue = JSON.stringify(value);
        await sessionStorage.setItem(key, jsonValue);
    } catch (e) {
        console.log(e);
    }
};

export const handleConfirm = (confirm, action) => { // confirm창 띄우고 => url 이동하는 함수
    const result = window.confirm(confirm);

    if (result) {
        action();
    }
}

export const userRoleType = (role) => {
    switch (role) {
        case 'SUPER': return "슈퍼관리자";
        case 'SCHOOL': return "학교관리자";
        case 'DEFAULT': return "일반관리자";
        case 'STUDENT': return "학생";
        default: break;
    }
}

export const userGrade = (role, grade, userClass, number) => {  //학년 반 번호 value+text 조합 함수
    if (role === 'DEFAULT') {
        return (grade ? `${grade}학년 ` : `-학년 `)
            + (userClass ? `${userClass}반 ` : `-반`);
    } else if (role === 'STUDENT') {
        return (grade ? `${grade}학년 ` : `-학년 `)
            + (userClass ? `${userClass}반 ` : `-반 `)
            + (number ? `${number}번` : `-번`);
    } else return "-";
}

export const thousandsSeparator = (e) => { //숫자 세 자리씩 끊는 함수
    return e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const refreshToken = async (token) => {
    return await axios.post(`/refresh-token?refresh=${token}`);
}

export const getDateTime = (v) => {
    return (v == null || ( v === "" ))? "-" : moment(v).format("YYYY-MM-DD HH:mm:ss");
}

export const lineChange = (v) => {
    return v?.split('\n').map((el, idx) => <span key={idx}>{el}<br/></span>)
}
