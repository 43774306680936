import React, { useState } from "react";
import Accordion from "../../components/commons/Accordion";

const navList = [
  {
    id: "main",
    title: "메인",
    auth: ["SUPER", "SCHOOL", "DEFAULT"],
    menus: [
      {
        subTitle: "대시보드",
        LinkTo: "dashboard",
        auth: ["SUPER", "SCHOOL", "DEFAULT"],
      },
      {
        subTitle: "통계자료",
        LinkTo: "statistics",
        auth: ["SUPER", "SCHOOL", "DEFAULT"],
      },
    ],
  },
  {
    id: "member",
    title: "회원정보",
    auth: ["SUPER", "SCHOOL", "DEFAULT"],
    menus: [
      {
        subTitle: "회원 리스트",
        LinkTo: "members",
        auth: ["SUPER", "SCHOOL", "DEFAULT"],
      },
    ],
  },
  {
    id: "contents",
    title: "콘텐츠 관리",
    auth: ["SUPER"],
    menus: [
      {
        subTitle: "키오스크 관리",
        LinkTo: "kiosk",
        auth: ["SUPER"],
      },
    ],
  },
  {
    id: "class",
    title: "클래스 관리",
    auth: ["SUPER", "SCHOOL", "DEFAULT"],
    menus: [
      {
        subTitle: "클래스 리스트",
        LinkTo: "class",
        auth: ["SUPER", "SCHOOL", "DEFAULT"],
      },
      {
        subTitle: "클래스 승인",
        LinkTo: "ClassAccept",
        auth: ["SUPER", "SCHOOL", "DEFAULT"],
      },
      {
        subTitle: "클래스 통계",
        LinkTo: "stats",
        auth: ["SUPER", "SCHOOL", "DEFAULT"],
      },
      {
        subTitle: "공지사항",
        LinkTo: "notice",
        auth: ["SUPER", "SCHOOL", "DEFAULT"],
      },
    ],
  },
  {
    id: "Admin",
    title: "관리자 및 학생 계정 관리",
    auth: ["SUPER", "SCHOOL", "DEFAULT"],
    menus: [
      {
        subTitle: "관리자 계정 추가 관리",
        LinkTo: "manager",
        auth: ["SUPER", "SCHOOL"],
      },
      {
        subTitle: "학생 계정 추가 관리",
        LinkTo: "student",
        auth: ["SUPER", "SCHOOL", "DEFAULT"],
      },
    ],
  },
  {
    id: "MyPage",
    title: "내 계정",
    auth: ["SUPER", "SCHOOL", "DEFAULT"],
    menus: [
      {
        subTitle: "내 계정 관리",
        LinkTo: "myPage",
        auth: ["SUPER", "SCHOOL", "DEFAULT"],
      },
    ],
  },

  {
    id: "QuizPage",
    title: "미션",
    auth: ["SUPER"],
    menus: [
      {
        subTitle: "미션 관리",
        LinkTo: "quizPage",
        auth: ["SUPER"],
      },
      {
        subTitle: "미션 추가",
        LinkTo: "addQuiz",
        auth: ["SUPER"],
      },
      {
        subTitle: "문제 관리",
        LinkTo: "question",
        auth: ["SUPER"],
      },
      {
        subTitle: "문제 추가",
        LinkTo: "addQuestion",
        auth: ["SUPER"],
      },
    ],
  },
];

const Sidenav = () => {
  const [checkAuth] = useState(JSON.parse(sessionStorage.getItem("manager")));

  return (
    <div className="sidenavWrapper">
      {navList.map((item, idx) => (
        <Accordion auth={checkAuth?.role} key={idx} item={item} />
      ))}
    </div>
  );
};

export default Sidenav;
