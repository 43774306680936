import React, { useState,useEffect } from 'react';
import axios from '../../Axios';
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { userRoleType } from '../../utils/Util';
import ice_symbol from '../../assets/img/ice_symbol.svg';

const Header = () => {
    const history = useHistory();
    const [userInfo, setUserInfo] = useState({
        role: '',
        name: '',
        id: ''
    });

    useEffect(()=> {
        const token = JSON.parse(sessionStorage.getItem("token"));

        if (token) {
            const decode_jwt = jwt_decode(token.jwt)
            setUserInfo({
                role: decode_jwt.roles[0],
                id: decode_jwt.aud
            })
        }

    },[]);

    const handleLogout = () => {
        const result = window.confirm('로그아웃 하시겠습니까?')

        if (result){
            axios.post(`/sign-out`)
                .finally(() => {
                    sessionStorage.removeItem("manager");
                    sessionStorage.removeItem("token");
                    window.location.href = "/";
                })
        }
    }

    return (
        <header className="headerWrapper">
            <span className="headerTitle" onClick={() => history.push('/main/dashboard')}>
            <img src={ice_symbol} alt="ice_symbol" />
                안전스쿨 관리자
            </span>
            <div className="logArea">
                <span className="welcomeWord">[ {userRoleType(userInfo.role)} ] {userInfo.name || userInfo.id}님 환영합니다.</span>
                <button className="logoutBtn" onClick={() => handleLogout()}>Log out</button>
            </div>
        </header>
    )
}

export default Header;
