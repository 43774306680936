import axios from "axios";
import { refreshToken, storeData } from './utils/Util';

const axiosInstance = axios.create();
//axiosInstance.defaults.baseURL = `${process.env.REACT_APP_SAFEEDU_API_URL}/v1/`;
axiosInstance.defaults.baseURL = `https://meta-safety.ice.go.kr/v1/`;



axiosInstance.interceptors.request.use (
    async (config) => {
        const token = JSON.parse(sessionStorage.getItem("token"));
        if (token) {
            config.headers.Authorization = `${token.jwt}`;
            config.headers["X-AUTH-TOKEN"] = `${token.jwt}`;
        } else {
            // 토큰이 없음 로그인 필요
            if (config.url !== '/sign-in' && !config.url.includes('/notice')) {
                window.location.replace("/");
            }
        }
        return config;
    },
    async (err) => {
        await Promise.reject(err);
    }
);

axiosInstance.interceptors.response.use (
    (res) => {
        return res;
    },
    async (err) => {
        const { config } = err;
        if (!err.response) return Promise.reject(err);
        const { status } = err?.response;

        if (status === 403) {
            const originalRequest = config;
            const token = JSON.parse(sessionStorage.getItem("token"));
            const result = await refreshToken(token.refresh);
            if (result.status === 200) {
                const data = result.data;
                storeData("token", {jwt: data.jwt, refresh: token.refresh});
                originalRequest.headers.Authorization = `${data.jwt}`;
                originalRequest.headers["X-AUTH-TOKEN"] = `${data.jwt}`;
                return axios(originalRequest);
            }
        }
        if (status === 500) {
            if (err.response?.data?.msg?.includes('JWT expired')) {
                sessionStorage.removeItem("token");
                window.location = '/';
            }
        }

        return Promise.reject(err);
    }
);

export default axiosInstance;
