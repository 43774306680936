import React, { useState } from 'react';
import { Link } from "react-router-dom";
import openArrow from '../../assets/img/arrow_up.png';
import closeArrow from '../../assets/img/arrow_down.png';

const Accordion = ({ auth, item }) => {
    const pathName = window.location.pathname.slice(5);

    const [isOpen, setIsOpen] = useState(false);
    const [allOpen, setAllOpen] = useState(true);

    const handleClick = () => {
        if (allOpen){
            setAllOpen(false)
        } else {
            setIsOpen(!isOpen)
        }
    }

    if (item.auth.indexOf(auth) === -1) return null;

    return (
        <div className="menuWrapper" key={item.title}>
            <div className="titleWrapper" onClick={()=>handleClick()}>
                {item.title}
                <img src={isOpen || allOpen ? openArrow : closeArrow} alt="icon" style={{width: 15, height: 15}} />
            </div>
            {
                item.menus.map((menu, idx) => {
                    if (menu.auth.indexOf(auth) === -1) {
                        return null;
                    } else if (!isOpen && !allOpen) {
                        return null;
                    }
                    else {
                        return <Link key={idx} to={`/main/${menu.LinkTo}`}
                                    style={{textDecoration: 'none', color: '#434343'}}>
                            <div className={pathName.includes(menu.LinkTo) ? "clicked" : "subTitleWrapper"}>
                                {menu.subTitle}
                            </div>
                        </Link>
                    }
                })
            }
        </div>
    )
}

export default Accordion;
