import React from 'react';
import ReactLoading from 'react-loading';

const Loading = () => {
    return (
        <div className="dark_background">
            <ReactLoading type={'spokes'} color={'#ffffff'} height={'30px'} width={'30px'} className={'loadingComponent'} />
        </div>
    );
};

export const DataLoading = () => {
    return (
        <div className="spinner_background">
            <ReactLoading type={'cylon'} color={'#FFBF17'} height={'30px'} width={'30px'} />
        </div>
    );
}

export default Loading;
