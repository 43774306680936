import React, { lazy } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Routes from "./Routes";

const isToken = () => !!sessionStorage.getItem("token");

const Unauthorized = ({ component: Component, restricted, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => (
                isToken() ? <Redirect to={"/main/dashboard"} /> : <Component {...props} />
            )}
        />
    )
};

const Authorized = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => (
                isToken() ? <Component {...props} /> : <Redirect to={"/login"} />
            )}
        />
    ) 
};

const AdminWebRouter = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Unauthorized
                    exact
                    component={lazy(() => import("../pages/Login"))}
                    path="/"
                />
                <Unauthorized
                    exact
                    component={lazy(() => import("../pages/Login"))}
                    path="/login"
                />
                <Route
                    exact
                    path="/view/notice"
                    component={lazy(() => import("../pages/mobile/List"))}
                />
                <Route
                    exact
                    path="/view/notice/:no"
                    component={lazy(() => import("../pages/mobile/webview"))}
                />
                <Route
                    exact
                    path="/view/credit"
                    component={lazy(() => import("../pages/mobile/credit"))}
                />
                <Authorized
                    component={Routes}
                    path="/main"
                />
            </Switch>
        </BrowserRouter>
    );
};

export default AdminWebRouter;
