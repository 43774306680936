import React, { Suspense } from "react";
import AdminWebRouter from "./router";

import './App.css';
import './assets/style/main.css';
import './assets/style/commons.css';
import './assets/style/util.css';
import Loading from "./utils/Loading";

function App() {
    return (
        <Suspense fallback={<Loading/>}>
            <AdminWebRouter/>
        </Suspense>
    );
};

export default App;
